<template>
  <div class="litigationDocuments">
    <van-nav-bar
      title="诉讼文书"
      left-arrow
      :safe-area-inset-top="true"
      @click-left="onClickLeft"
      :fixed="true"
      :border="false"
      :placeholder="true"
      v-show="is_navbar === 'true'"
    />
    <div class="litigationDocumentsCenter" v-show="!defaultPage">
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <div
          class="litigationDocuments"
          v-for="(item, index) in goodList"
          :key="index"
          @click="goLitigationDocuments(item.url)"
        >
          <div class="compensateForItem">
            <div class="compensateForItemLeft">
              <img :src="item.pic" alt="" srcset="" class="headImg" />
            </div>
            <div class="compensateForItemRight">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="priceDate">{{ getMoment(item.publish_at) }}</div>
            </div>
          </div>
          <van-divider />
        </div>
      </van-list>
    </div>
    <div class="defaultPageContainer" v-show="defaultPage">
      <div class="imgContainer">
        <img
          src="../../static/images/defaultPage@2x.png"
          alt=""
          class="defaultPageImg"
        />
        <div class="defaultPageText">什么都没有哦~</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMaterialList } from "@/api/security";
export default {
  name: "LitigationDocuments",
  data() {
    return {
      goodList: [],
      //   是否处于加载状态，加载过程中不触发load事件
      loading: false,
      finished: false,
      page: 1,
      page_size: 10,
      type: 5,
      defaultPage: false,
      //   是否处于加载状态，加载过程中不触发load事件
      loading: false,
      finished: false,
      is_navbar: "true",
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == "/litigationdocumentsdeatil") {
      if (!from.meta.keepAlive) {
        from.meta.keepAlive = true;
      }
      next();
    } else {
      from.meta.keepAlive = false;
      next();
    }
  },
  mounted() {
    if (JSON.stringify(this.$route.query) != "{}") {
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onLoad() {
      let config = {
        type: this.type,
        page: this.page,
        page_size: this.page_size,
      };
      this.requestGetMaterialList(config);
    },
    // 商品列表请求
    async requestGetMaterialList(config) {
      this.loading = true;
      try {
        const result = await getMaterialList(config);
        if (result.code === 20000 || result.status_code === 1000) {
          let goodList = [...this.goodList, ...result.data.data];
          let newobj = {};
          // 去重
          this.goodList = goodList.reduce((preVal, curVal) => {
            newobj[curVal.id] ? "" : (newobj[curVal.id] = preVal.push(curVal));
            return preVal;
          }, []);
          // 没有数据了
          if (result.data.data.length < this.page_size) {
            this.finished = true;
          } else {
            this.page += 1;
            this.loading = false;
          }
          if (this.page === 1 && result.data.data.length === 0) {
            this.defaultPage = true;
          } else {
            this.defaultPage = false;
          }
          // 缺省页
        } else {
          this.$toast(result.message);
        }
      } catch (error) {
        this.$toast("请求失败");
      }
    },
    goLitigationDocuments(url) {
      this.$router.push({
        name: "LitigationDocumentsDeatil",
        params: {
          url: url,
        },
      });
    },
    getMoment(time) {
      return this.$moment(time).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="less" scoped>
.litigationDocuments {
  .litigationDocumentsCenter {
    width: 100%;
    height: 100px;
    padding: 16px 20px 0px 20px;
    box-sizing: border-box;
    .litigationDocuments {
      .compensateForItem {
        display: flex;
        .compensateForItemLeft {
          width: 82px;
          height: 82px;
          border-radius: 4px;
          .headImg {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            border: 1px solid #f2f2f2;
          }
        }
        .compensateForItemRight {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            width: 241px;
            height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            line-height: 20px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .priceDate {
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #888888;
            line-height: 17px;
          }
        }
      }
    }
  }
  .defaultPageContainer {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .imgContainer {
      width: 161px;
      height: 160px;
      .defaultPageImg {
        width: 100%;
        height: 100%;
      }
      .defaultPageText {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}
::v-deep .van-nav-bar .van-icon {
  color: black;
}
</style>
